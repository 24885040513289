/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Input from '../../components/PasswordInput';
import api from '../../services/api';
import logo from '../../assets/logotipo.png';
import { schemaPassword } from './schemas/schemas';

// import { Container } from './styles';
interface DataContent {
  password: string;
  password_confirmation: string;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

interface Props {
  match: {
    params: {
      token: string;
    };
  };
}

export default function ChangePassword(props: Props): JSX.Element {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (data) => {
      try {
        await schemaPassword.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const { token } = props.match.params;
        await api.post('users-password/reset', { ...data, token });
        toast.success('Senha mudada com sucessor. Faça o login');
        api.defaults.headers.Authorization = '';
        history.push('/');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
          return;
        }
        toast.error('Falha em mudar senha, tente reenviar link');
      }
    },
    [props.match.params, history]
  );
  return (
    <>
      <img src={logo} width="250" alt="appy-hour" />
      <Form onSubmit={handleSubmit} ref={formRef} translate="no">
        <Input className="usual" placeholder="Nova Senha" name="password" />
        <Input
          className="usual"
          name="password_confirmation"
          placeholder="Confirmação de Senha"
        />

        <button type="submit">
          {loading ? 'Carregando...' : 'Mudar Senha'}
        </button>
        <Link to="/">Fazer Login</Link>
      </Form>
    </>
  );
}
